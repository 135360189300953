.request-unit-spare-desktop__container{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:'oem oem oem phone phone phone'
                        'what-find what-find what-find upload upload upload';
    gap: 10px;
}
.request-unit-spare-desktop__container-preview{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:' phone phone phone'
                        'what-find what-find what-find upload upload upload';
    gap: 10px;
}
.request-unit-spare-desktop__container{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    // grid-template-areas:'year year brand brand model model'
    //     'volume volume petrol petrol power power'
    //     'body body engine engine . .'
    //     'autopart autopart autopart oem oem oem'
    //     'add add . . . .'
    //     'phone phone phone . . .'
    //     'what-find what-find what-find upload upload upload'
    //     '. . . . . .'
    //     '. . . . . .'
    //     'info_1 info_1 info_1 info_1 info_1 info_1'
    //     'info_2 info_2 info_2 info_2 info_2 info_2';
    &-preview{
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        // grid-template-areas:'preview preview preview preview preview preview' 
        //                     'year year brand brand model model'
        //                     'volume volume petrol petrol power power'
        //                     'body body engine engine . .'
        //                     'autopart autopart autopart oem oem oem'
        //                     'add add . . . .'
        //                     'phone phone phone . . .'
        //                     'what-find what-find what-find upload upload upload'
        //                     '. . . . . .'
        //                     '. . . . . .'
        //                     'info_1 info_1 info_1 info_1 info_1 info_1'
        //                     'info_2 info_2 info_2 info_2 info_2 info_2';
        gap: 10px;
    }
}

.request-unit-spare-desktop__wrap-upload-images{
    grid-area: upload;
    top: 20px;
}
// -----------------------------------------------------------
.request-repair-desktop__container{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto auto;
    grid-template-areas: 
                        'country country city city . .'
                        'what-find what-find what-find upload upload upload';
    gap: 10px;
}

.request-repair-desktop__wrap-country{
    grid-area: country;
}

.request-repair-desktop__wrap-city{
    grid-area: city;
}

.request-repair-desktop__wrap-text{
    grid-area: what-find;
}

.request-repair-desktop__wrap-upload-images{
    grid-area: upload;
    top: 20px;
}
// -----------------------------------------------------------
.request-truck-desktop__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'what-find upload';
    gap: 10px;
}

.request-truck-desktop__wrap-country{
    grid-area: country;
}

.request-truck-desktop__wrap-city{
    grid-area: city;
}

.request-truck-desktop__wrap-text{
    grid-area: what-find;
}

.request-truck-desktop__wrap-upload-images{
    grid-area: upload;
    margin-top: 37px;
}