.option-select{
    // option-select__container
    &__container{
        padding: 0;
        position: relative;

        // option-select__container--offset-top
        &--offset-top{
            position: fixed;
            width: calc( 100% - var(--padding-size-modal) - 10px);
            top:70px;
            z-index: 99999;
            left: 15px;
            // left: calc( var(--padding-size-modal) * 2 );
            &::after{
                width: 100vw;
                height: 100vh;
                top: 0;
                left: -15px;
                content: '';
                position: absolute;
                // filter: blur(200px);
                opacity: .8;
                z-index: 998;
                background: #000;
                pointer-events: none;
            }
        }

    }


    // option-select__list
    &__list{
        padding: 0;
        z-index: 9999;

        &>div:last-child {
            border-color: red;
        }
    }
}
